@use '../../../scss/mixins';
@use '../../../scss/variables';
@use '../../../scss/keyframes';

.dropzone {
  width: 50%;
  aspect-ratio: 1 / 1;
  margin-bottom: 60px;
  cursor: pointer;
  border-radius: 50%;
  @include mixins.flexbox;
  padding: 7px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  background: white;
  box-shadow: variables.$shadow-surround;
  font-size: 14px;

  .inside-border {
    width: 100%;
    height: 100%;
    border: 1px dashed variables.$mui-border;
    @include mixins.flexbox;
    border-radius: 50%;
    transition: variables.$trans-speed;

    p {
      width: 90%;
      text-align: center;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
  }
  
  .drag-active {
    border: 1px dashed black;
  }

  &:hover {
    .inside-border {
      border: 1px dashed black;
    }
  }
}

.dropzone-error {
  border: 1px solid variables.$error;

  .inside-border {
    border: 1px dashed variables.$error;
  }

  &:hover {
    border: 1px solid variables.$error;
  }
}

@media screen and ( max-width: 550px ) {
  .dropzone {
    width: 60%;
  }
}