@font-face {
  font-family: 'formula1-italic';
  src: url('../../public/fonts/f1_italic/Formula1-Italic.eot');
  src: url('../../public/fonts/f1_italic/Formula1-Italic.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/f1_italic/Formula1-Italic.woff2') format('woff2'),
      url('../../public/fonts/f1_italic/Formula1-Italic.woff') format('woff'),
      url('../../public/fonts/f1_italic/Formula1-Italic.ttf') format('truetype'),
      url('../../public/fonts/f1_italic/Formula1-Italic.svg#Formula1-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'formula1-black';
  src: url('../../public/fonts/f1-black/Formula1-Black.eot');
  src: url('../../public/fonts/f1-black/Formula1-Black.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/f1-black/Formula1-Black.woff2') format('woff2'),
      url('../../public/fonts/f1-black/Formula1-Black.woff') format('woff'),
      url('../../public/fonts/f1-black/Formula1-Black.ttf') format('truetype'),
      url('../../public/fonts/f1-black/Formula1-Black.svg#Formula1-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'formula1-bold-4';
  src: url('../../public/fonts/f1-bold-4/Formula1-Bold.eot');
  src: url('../../public/fonts/f1-bold-4/Formula1-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/f1-bold-4/Formula1-Bold.woff2') format('woff2'),
      url('../../public/fonts/f1-bold-4/Formula1-Bold.woff') format('woff'),
      url('../../public/fonts/f1-bold-4/Formula1-Bold.ttf') format('truetype'),
      url('../../public/fonts/f1-bold-4/Formula1-Bold.svg#Formula1-Bold') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'formula1-bold-web';
  src: url('../../public/fonts/f1-bold-web/Formula1-Bold-Web.eot');
  src: url('../../public/fonts/f1-bold-web/Formula1-Bold-Web.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/f1-bold-web/Formula1-Bold-Web.woff2') format('woff2'),
      url('../../public/fonts/f1-bold-web/Formula1-Bold-Web.woff') format('woff'),
      url('../../public/fonts/f1-bold-web/Formula1-Bold-Web.ttf') format('truetype'),
      url('../../public/fonts/f1-bold-web/Formula1-Bold-Web.svg#Formula1-Bold') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'formula1-regular';
  src: url('../../public/fonts/f1-regular/Formula1-Regular.eot');
  src: url('../../public/fonts/f1-regular/Formula1-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/f1-regular/Formula1-Regular.woff2') format('woff2'),
      url('../../public/fonts/f1-regular/Formula1-Regular.woff') format('woff'),
      url('../../public/fonts/f1-regular/Formula1-Regular.ttf') format('truetype'),
      url('../../public/fonts/f1-regular/Formula1-Regular.svg#Formula1-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'formula1-wide';
  src: url('../../public/fonts/f1-wide/Formula1-Wide.eot');
  src: url('../../public/fonts/f1-wide/Formula1-Wide.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/f1-wide/Formula1-Wide.woff2') format('woff2'),
      url('../../public/fonts/f1-wide/Formula1-Wide.woff') format('woff'),
      url('../../public/fonts/f1-wide/Formula1-Wide.ttf') format('truetype'),
      url('../../public/fonts/f1-wide/Formula1-Wide.svg#Formula1-Wide') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}